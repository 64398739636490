<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div>
          <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
            <a-col :span="18">
              <h3 class="mb-0" style="font-size: 20px;">Updates</h3>
            </a-col>
            <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
              <a-button type="primary" class="ml-4" size="large" @click="createUpdateModal">
                <a-icon type="plus" /> Create new update
              </a-button>
            </a-col>
          </a-row>
          <a-card>
            <a-table
              :columns="columns"
              :data-source="updates"
              :rowKey="(record, index) => index"
            >
              <template slot="updateName" slot-scope="text, record">
                  <span>
                    <a href="javascript:;" @click="() => editUpdateModal(record)">{{ text }}</a>
                  </span>
              </template>
              <template slot="created_at" slot-scope="created_at">
                  <span>
                    <a-icon type="calendar" /> {{ $moment(created_at).format('DD.MM.YY') }}
                  </span>
              </template>
              <template slot="storiesTabs" slot-scope="stories">
                <a-badge :count="stories.length" :number-style="{ backgroundColor: '#fff', color: '#000', fontSize: '12px', padding: '0px'  }">
                  <a-icon class="font-size-24" type="mobile" />
<!--                  <a-icon v-for="n in stories.length" :key="n" type="mobile" />-->
                </a-badge>
              </template>
              <template slot="apps" slot-scope="apps">
                <div class="row-operations">
                  <span v-for="app in apps" :key="app.id">
                    <a-tooltip :title="'version ' + app.min_app_version + ' - ' + app.max_app_version" placement="bottom">
                      <a-tag>
                        {{ app.name }}
                        <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="text-success" />
                        <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                        <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="text-default" />
                      </a-tag>
                    </a-tooltip>
                  </span>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record">
                <div class="row-operations d-flex justify-content-end">
                  <span>
                    <a-tooltip :title="record.is_active ? 'Switch off' : 'Switch on'" placement="bottom">
                      <a-switch
                      size="small"
                      class="mr-2"
                      :checked="Boolean(record.is_active)"
                      @change="() => onSwitch(record.id)"
                      />
                    </a-tooltip>
                  </span>
                  <span>
                    <a-tooltip title="Edit update" placement="bottom">
                      <a class="font-size-18 text-primary" href="javascript:;" @click="() => editUpdateModal(record)"><a-icon type="edit" /></a>
                    </a-tooltip>
                  </span>
                  <span>
                    <a-tooltip title="Delete update" placement="bottom">
                      <a-popconfirm
                        v-if="updates.length"
                        title="Sure to delete this update?"
                        @confirm="() => onDelete(record.id)"
                      >
                        <a class="font-size-18 text-danger" href="javascript:;"><a-icon type="delete" /></a>
                      </a-popconfirm>
                    </a-tooltip>
                  </span>
                </div>
              </template>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <a-modal
      v-model="updateModal"
      :title="updateObj && updateObj.id ? 'Edit update' : 'Create update'"
      :footer="null"
      class="update-modal"
      width="1000px"
      :destroyOnClose="true"
      @cancel="closeUpdateModal"
    >
      <update-modal
        @refreshUpdates="getUpdates"
        @changeUpdates="handleUpdate"
        @closeModalFunction="updateModal = false"
        @copyColor="copyColor"
        :update-obj="updateObj"
        :def-obj="defObj"
        :cp-color="copiedColor"
        ref="updateModal"
      />
    </a-modal>
  </div>
</template>

<script>
import DEFAULT_UPDATE from './default_update'
// import DEFAULT_STORY from './default_story'
import updateModal from './updateModal.vue'
import apiClient from '@/services/axios'

const columns = [
  {
    title: 'Update name',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'updateName' },
  },
  {
    title: 'Date start',
    dataIndex: 'starts_at',
    key: 'starts_at',
    // customRender: text => formatDate(text),
    scopedSlots: { customRender: 'created_at' },
  },
  // {
  //   title: 'Date end',
  //   dataIndex: 'ends_in',
  //   key: 'ends_in',
  //   // customRender: text => formatDate(text),
  //   scopedSlots: { customRender: 'created_at' },
  // },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Apps',
    dataIndex: 'applications',
    key: 'applications',
    scopedSlots: { customRender: 'apps' },
  },
  {
    title: 'Number of tabs',
    dataIndex: 'stories',
    key: 'stories',
    // customRender: stories => stories.length,
    scopedSlots: { customRender: 'storiesTabs' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {
    updateModal,
  },
  data() {
    return {
      updates: [],
      columns,
      updateObj: undefined,
      defObj: { ...DEFAULT_UPDATE },
      cObj: undefined,
      updateModal: false,
      copiedColor: null,
    }
  },
  created() {
    this.getUpdates()
  },
  methods: {
    createUpdateModal() {
      // this.updateObj = { ...DEFAULT_UPDATE, stories: [DEFAULT_STORY] }
      this.updateObj = { ...DEFAULT_UPDATE }
      this.updateModal = true
    },
    editUpdateModal(obj) {
      this.updateObj = JSON.parse(JSON.stringify(obj))
      // this.cObj = JSON.parse(JSON.stringify(obj))
      this.updateModal = true
      console.log(obj)
    },
    closeUpdateModal() {
      this.$refs.updateModal.closeEditModal()
    },
    handleUpdate(obj) {
      console.log('successfully updated. updating list...')
      this.updateModal = false
      this.getUpdates()
      console.log(obj)
      console.log(this.updates)
    },
    copyColor(color) {
      // ---
      this.copiedColor = color
      console.log('parent copied color:', this.copiedColor)
    },
    getUpdates() {
      const url = '/admin/updates'
      apiClient.get(url).then((response) => {
        this.updates = response.data.data
        console.log(this.updates)
      }).catch(error => { console.log(error) })
    },
    onDelete(key) {
      const target = this.updates.filter(item => key === item.id)[0]
      const url = '/admin/updates/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'Update "' + target.name + '" successfully deleted',
          description: '',
        })
        this.updates.splice(this.updates.findIndex(x => x.id === target.id), 1)
        // this.appData = dataSource.filter(item => item.application_id !== key)
        // this.cacheData = this.appData.map(item => ({ ...item }))
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting update',
          description: error.message,
        })
      })
    },
    onSwitch(key) {
      const target = this.updates.filter(item => key === item.id)[0]
      const activeness = target.is_active
      const newActiveness = activeness === 0 ? 1 : 0
      const url = `/admin/updates/${key}/status`
      target.is_active = newActiveness
      apiClient.patch(url, { is_active: newActiveness }).then((response) => {
        this.$notification.success({
          message: `Update ${target.name} switched ${activeness === 1 ? 'off' : 'on'}`,
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while switching update',
          description: error.message,
        })
        target.is_active = activeness
      })
    },
  },
}
</script>
<style>
  .ant-table-thead th {
    background-color: #FAFAFA !important;
    font-weight: 600 !important;
  }
  .ant-table-tbody td {
    color: #595959;
  }
</style>
<style scoped>
  button:not(.ant-switch) {
    border-radius: 2px !important;
  }
  .row-operations a {
    margin-right: 8px;
  }
</style>
